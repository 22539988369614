import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import Title from '../Title/Title';
import AboutImg from '../Image/AboutImg';
import PortfolioContext from '../../context/context';

const Partners = () => {
  const { partners } = useContext(PortfolioContext);
  const { img, img2, img2_url } = partners;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="partners">
      <Container>
        <Title title="Partners" />
        <Row className="about-wrapper">
          {/* <Col md={6} sm={12}>
            <Fade bottom duration={1000} delay={600} distance="30px">
              <div className="about-wrapper__image">
                <AboutImg alt="profile picture" filename={img} />
              </div>
            </Fade>
          </Col> */}
          <Col md={12} sm={12}>
            <Fade bottom duration={1000} delay={600} distance="30px">
              <a className="about-wrapper__image" href={img2_url} target="_blank" rel="noreferrer·noopener">
                <AboutImg alt="profile picture" filename={img2} />
              </a>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Partners;
